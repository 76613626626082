import React from "react";
import styled from "styled-components";
import { Text } from "../../components/Text/Text";
import doubleQuote from "../../images/doubleQuote.svg";
import star from "../../images/star.svg";
import starActive from "../../images/starActive.svg";
import { color, typography } from "../../shared/style";
import htmlSerializer from "../../components/RichtextHelper/CustomText";
import { RichText } from "prismic-reactjs";
import PropTypes from "prop-types";
import { CarouselSlick } from "../../components/CarouselSlick/CarouselSlick";
export const Testimoials = ({ data }) => {
  if (!data?.items || !data?.primary) return null;
  const { items, primary } = data;

  const RatingResult = ({ rating }) => {
    const rate = [];
    for (let i = 0; i < 5; i++) {
      if (i < rating) rate.push(<IconStar src={starActive} />);
      else rate.push(<IconStar src={star} />);
    }
    return (
      <>
        {rate?.map((item, i) => (
          <div key={i}>{item}</div>
        ))}
      </>
    );
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
          appendDots: (dots) => (
            <div
              style={{
                position: "initial",
                marginTop: "20px",
              }}
            >
              <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
          ),
        },
      },
      {
        breakpoint: 756,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          nextArrow: null,
          prevArrow: null,
          appendDots: (dots) => (
            <div
              style={{
                position: "initial",
                marginTop: "20px",
              }}
            >
              <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
          ),
        },
      },
    ],
  };

  return (
    <div id="review">
      <Wrapper>
        <WrapperTitle>
          <Title
            tag="h3"
            lineHeight={90}
            capital="uppercase"
            color={color.primary.blue}
          >
            <RichText
              render={primary?.title1?.raw || primary?.title?.raw}
              htmlSerializer={htmlSerializer}
            />
          </Title>
        </WrapperTitle>
        <Content>
          {/* <Desktop>
          {items?.map((item, i) => (
            <WrapperItem key={i}>
              <Item>
                <Info>
                  <Avatar src={item?.avatar.url} />
                  <Detail>
                    <Icon src={doubleQuote} />
                    <Name
                      font="Oxygen"
                      size={20}
                      lineHeight={28}
                      weight={typography.weight.bold}
                      color={color.netraul.black100}
                    >
                      {item.name.text}
                    </Name>
                    <Position
                      font="Oxygen"
                      weight={typography.weight.regular}
                      size={14}
                      lineHeight={22}
                      color={color.netraul.black100}
                    >
                      {item.position.text}
                    </Position>
                    <Rate>
                      <RatingResult rating={item?.rating} />
                    </Rate>
                  </Detail>
                </Info>
                <Comment
                  font="Oxygen"
                  lineHeight={26}
                  color={color.netraul.black90}
                >
                  <RichText
                    render={item?.comment?.raw}
                    htmlSerializer={htmlSerializer}
                  />
                </Comment>
              </Item>
            </WrapperItem>
          ))}
        </Desktop>
        <Mobile> */}
          <CarouselSlick settings={{ ...settings }}>
            {items?.map((item, i) => (
              <WrapperItem key={i}>
                <Item>
                  <Info>
                    <Avatar src={item?.avatar.url} />
                    <Detail>
                      <Icon src={doubleQuote} />
                      <Name
                        font="Oxygen"
                        size={20}
                        lineHeight={28}
                        weight={typography.weight.bold}
                        color={color.netraul.black100}
                      >
                        {item?.name?.text}
                      </Name>
                      <Position
                        font="Oxygen"
                        weight={typography.weight.regular}
                        size={14}
                        lineHeight={22}
                        color={color.netraul.black100}
                      >
                        {item?.position?.text}
                      </Position>
                      <Rate>
                        <RatingResult rating={item?.rating} />
                      </Rate>
                    </Detail>
                  </Info>
                  <Comment
                    font="Oxygen"
                    lineHeight={26}
                    color={color.netraul.black90}
                  >
                    <RichText
                      render={item?.comment?.raw}
                      htmlSerializer={htmlSerializer}
                    />
                  </Comment>
                </Item>
              </WrapperItem>
            ))}
          </CarouselSlick>
          {/* </Mobile> */}
        </Content>
      </Wrapper>
    </div>
  );
};
const Wrapper = styled.div`
  max-width: 1240px;
  width: 100%;
  margin-bottom: 100px !important;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: hidden;
  @media (max-width: 1300px) {
    padding: 0 40px;
    box-sizing: border-box;
  }

  @media (max-width: 650px) {
    margin-bottom: 40px !important;
    padding: 20px;
  }
  .slick-dots li button:before {
    font-size: 16px;
    color: ${color.primary.blue};
  }
`;

const Content = styled.div`
  margin: 0 -17px;
  box-sizing: border-box;
  .slick-track {
    display: flex !important;
  }
  .slick-slide {
    height: inherit !important;
    & > div {
      height: 100%;
    }
  }
`;

const WrapperItem = styled.div`
  height: 100%;
  width: auto !important;
  display: block;
  margin: 0 17px;
  padding-bottom: 20px;
  box-sizing: border-box;
  background: linear-gradient(to bottom, #fff 30px, #f4f6f8 30px);

  @media (max-width: 1000px) {
    margin-bottom: 10px !important;
  }
  @media (max-width: 650px) {
    padding-bottom: 0;
  }
`;
const Item = styled.div`
  padding: 0 19px 20px 20px;
  box-sizing: border-box;
  @media (max-width: 1100px) and (min-width: 1000px) {
    padding: 0 10px;
  }
`;

const Icon = styled.img`
  width: 22px;
  padding: 20px 0;
`;
const IconStar = styled.img`
  width: 16px;
  height: 15px;
`;
const Info = styled.div`
  display: flex;
  margin-bottom: 40px;
`;
const Avatar = styled.img`
  max-height: 136px;
  max-width: 102px;
  object-fit: cover;
`;
const Detail = styled.div`
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1190px) and (min-width: 1100px) {
    padding-left: 20px;
  }
  @media (max-width: 1100px) and (min-width: 1000px) {
    padding-left: 20px;
  }
`;
const Name = styled(Text)``;
const Position = styled(Text)`
  opacity: 0.67;
`;
const Comment = styled(Text)``;
const Rate = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const WrapperTitle = styled.div`
  text-align: center;
  margin: 0 auto;
  margin-bottom: 70px;
  width: 60%; 
  @media (max-width: 650px) {
    margin-bottom: 40px;
    text-align: left;
    width: 100%;
  }
  @media (max-width: 1204px) and (min-width: 1000px) {
    width: 67%;
  }
  @media (max-width: 855px) and (min-width: 701px) {
    width: 68%;
  }
`;
const Title = styled(Text)`
  width: fit-content;
  margin: 0 auto;
  font-weight: ${typography.weight.regular1};
  @media (max-width: 650px) {
  font-weight: ${typography.weight.regular1};
    text-align: left;
    line-height: 62px;
    &br {
      display: none;
    }
  }
`;

Testimoials.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        rating: PropTypes.string,
        image: PropTypes.shape({ url: PropTypes.string }),
        name: PropTypes.shape({
          raw: PropTypes.arrayOf(
            PropTypes.shape({
              type: PropTypes.string,
              text: PropTypes.string,
            })
          ),
        }),
        position: PropTypes.shape({
          raw: PropTypes.arrayOf(
            PropTypes.shape({
              type: PropTypes.string,
              text: PropTypes.string,
            })
          ),
        }),
        comment: PropTypes.shape({
          raw: PropTypes.arrayOf(
            PropTypes.shape({
              type: PropTypes.string,
              text: PropTypes.string,
            })
          ),
        }),
      })
    ),
    primary: PropTypes.shape({
      title: PropTypes.shape({
        raw: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.string,
            text: PropTypes.string,
          })
        ),
      }),
    }),
  }),
};
