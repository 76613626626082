import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Text } from "../../components/Text/Text";
import { Button } from "../../components/Button/Button";
import { color, typography } from "../../shared/style";
import htmlSerializer from "./RichtextHelper/CustomText";
import { RichText } from "prismic-reactjs";
import PropTypes from "prop-types";
import { isMobile, isTablet } from "../../hooks/checkMobile";
import linkedin from "../../images/linkedin.svg";
import { redirectPage } from "../../hooks/redirect";

export const Team = ({ data }) => {
  const { items, primary } = data || {};
  const [isHover, setHover] = useState(false);
  const [index, setIndex] = useState(false);
  const [counterSeeMore, setCounterSeeMore] = useState(1);
  const [dataShow, setDataShow] = useState([]);
  const numberOfLine = isMobile ? 2 : 3;
  useEffect(() => {
    const onInitDataShow = () => {
      let tempData = [];
      if (isTablet) {
        tempData = items?.slice(0, counterSeeMore * numberOfLine);
      } else tempData = items;
      setDataShow(tempData);
    };
    onInitDataShow();
  }, [counterSeeMore, items, numberOfLine]);
  if (!items || !primary) return null;
  return (
    <div id="believe">
      <Wrapper>
        <WrapperTitle>
          <Title
            tag="h3"
            weight={typography.weight.regular1}
            capital="uppercase"
            color={color.primary.blue}
          >
            <RichText
              render={primary?.title1?.raw || primary?.title?.raw}
              htmlSerializer={htmlSerializer}
            />
          </Title>
        </WrapperTitle>
        <Content>
          {dataShow?.map((item, i) => {
            const linkedin_link = item?.member?.document?.data?.contacts.find(
              (item) => item.contact_label === "linkedin"
            );
            return (
              <Item key={i}>
                <Info
                  onMouseEnter={() => {
                    setHover(true);
                    setIndex(i);
                  }}
                  onMouseLeave={() => {
                    setHover(false);
                    setIndex(-1);
                  }}
                // onClick={() => redirectPage("#")}
                >
                  <WrapperAvatar>
                    <Avatar src={item.member.document?.data?.avatar?.url} />
                    {((isHover && i === index) || isTablet) && linkedin_link && (
                      <ButtonIcon
                        onClick={() =>
                          redirectPage({
                            url: linkedin_link?.contact_link?.url,
                            type: linkedin_link?.contact_link?.type,
                            link_type: linkedin_link?.contact_link?.link_type,
                          })
                        }
                        icon={linkedin}
                      ></ButtonIcon>
                    )}
                  </WrapperAvatar>
                  <Detail>
                    <Name
                      tag="h5"
                      capital="uppercase"
                      font="Alternate Gothic No1 D"
                      lineHeight={36}
                      size={36}
                      weight={typography.weight.regular1}
                      color={
                        isHover && i === index
                          ? color.primary.blue
                          : color.netraul.black100
                      }
                    >
                      {item.member.document?.data?.name?.text} {item.member.document?.data?.last_name?.text}
                    </Name>
                    <Position
                      font="Oxygen"
                      weight={typography.weight.regular}
                      size={14}
                      lineHeight={22}
                      color={color.netraul.black60}
                    >
                      {item.member.document?.data?.position?.text}
                    </Position>
                  </Detail>
                </Info>
              </Item>
            );
          })}
        </Content>
        {isTablet && counterSeeMore * numberOfLine < data?.items?.length && (
          <Btn
            type="outline_primary"
            onClick={() => setCounterSeeMore(counterSeeMore + 1)}
          >
            See more
          </Btn>
        )}
      </Wrapper>
    </div>
  );
};

const ButtonIcon = styled.div`
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  right: 10px;
  bottom: 15px;
  display: flex;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${(props) => props.icon && props.icon});
  background-color: white;
  /* @media (max-width: 650px) {
    top: 195px;
  }
  @media (max-width: 450px) {
    top: 105px;
  } */
  &:hover {
    transform: scale(1.1);
    transition: transform 0.5s;
    opacity: 0.9;
  }
`;
const Btn = styled(Button)`
  color: #2a2a2a;
  display: flex;
  margin: 0 auto;
  margin-top: 40px;
  border: 1px solid ${color.primary.blue};
`;
const Wrapper = styled.div`
  max-width: 1270px;
  width: 100%;
  margin: 0 auto;
  margin-top: 150px;
  @media (max-width: 1000px) {
    margin-bottom: 100px;
    margin-top: 80px;
  }
  @media (max-width: 650px) {
    margin-top: 80px;
    margin-bottom: 80px;
  }
`;
const WrapperTitle = styled.div`
  text-align: center;
  margin-bottom: 10px;
  @media (max-width: 650px) {
    margin-bottom: 6px;
  }
`;
const Title = styled(Text)`
  width: fit-content;
  margin: 0 auto;
  line-height: 90px;
  @media (max-width: 650px) {
    line-height: 62px;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  flex-wrap: wrap;
  justify-content: center;
  @media (max-width: 650px) {
    padding: 6px 10px 0 10px;
  }
`;
const Item = styled.div`
  max-width: 254px;
  box-sizing: border-box;
  width: 100%;
  padding: 0 15px;
  margin-top: 60px;
  @media (max-width: 650px) {
    max-width: 50%;
    padding: 10px;
    margin-top: 14px;
  }
`;
const Info = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
`;
const WrapperAvatar = styled.div`
  width: 100%;
  aspect-ratio: 1 / 1;
  position: relative;
`;
const Avatar = styled.img`
  width: 100%;
`;
const Detail = styled.div`
  display: flex;
  flex-direction: column;
`;
const Name = styled(Text)`
  padding: 24px 0 8px 0;
  letter-spacing: -0.015em;
  cursor: pointer;
  @media (max-width: 650px) {
    font-size: 30px !important;
  }
`;
const Position = styled(Text)`
  cursor: pointer;
  margin-bottom: 34px;
  @media (max-width: 650px) {
    margin-bottom: 0;
  }
`;
Team.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        button_label: PropTypes.shape({ text: PropTypes.string }),
        image: PropTypes.shape({ url: PropTypes.string }),
        name: PropTypes.shape({
          raw: PropTypes.arrayOf(
            PropTypes.shape({
              type: PropTypes.string,
              text: PropTypes.string,
            })
          ),
        }),
        position: PropTypes.shape({
          raw: PropTypes.arrayOf(
            PropTypes.shape({
              type: PropTypes.string,
              text: PropTypes.string,
            })
          ),
        }),
      })
    ),
    primary: PropTypes.shape({
      title: PropTypes.shape({
        raw: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.string,
            text: PropTypes.string,
          })
        ),
      }),
    }),
  }),
};
