import React from 'react'
import styled from "styled-components";
import {Text} from "../../components/Text/Text"
import htmlSerializer from "./RichtextHelper/CustomText";
import { RichText } from "prismic-reactjs";
import { color, typography } from "../../shared/style";
// import partnerIcon from "../../images/partnerIcon.svg"
// const data = [
//   {
//     url: partnerIcon
//   },
//   {
//     url: partnerIcon
//   },
//   {
//     url: partnerIcon
//   },
//   {
//     url: partnerIcon
//   },
//   {
//     url: partnerIcon
//   },
//   {
//     url: partnerIcon
//   },
//   {
//     url: partnerIcon
//   },
//   {
//     url: partnerIcon
//   },
//   {
//     url: partnerIcon
//   },
//   {
//     url: partnerIcon
//   },
//   {
//     url: partnerIcon
//   },
//   {
//     url: partnerIcon
//   },
//   {
//     url: partnerIcon
//   },
//   {
//     url: partnerIcon
//   },
//   {
//     url: partnerIcon
//   },
// ]
// const title = [
//   {
//     "type": "preformatted",
//     "text": "<span>We love our partners</span>",
//     "spans": []
//   }
// ]
export const Partners = ({data}) => {
  const {items, primary} = data || {};
  if(!items || !primary) {
    return null;
  }
  return (
    <div id="partner">
      <WrapperSection>
        <Info>
          <WrapperTitle>
            <Title
              tag="h3"
              weight={typography.weight.regular1}
              capital="uppercase"
              color={color.primary.blue}
            >
              <RichText render={primary?.title1?.raw || primary?.title?.raw} htmlSerializer={htmlSerializer} />
            </Title>
          </WrapperTitle>
          <WrapperDescription>
            <Description
              font={typography.type.primary}
              weight={typography.weight.regular}
              color={color.netraul.black80}
            >
              <RichText render={primary?.description1?.raw} htmlSerializer={htmlSerializer} />
            </Description>
          </WrapperDescription>
        </Info>
        <Content>
          {items && items?.map((item) => (
            <Item src={item?.partner?.document?.data?.logo?.url} />
          ))}
        </Content>
      </WrapperSection>
    </div>
  );
};
const WrapperSection = styled.div`
  max-width: 1236px;
  width: 100%;
  margin: 0 auto;
  margin-top: 120px;
  margin-bottom: 150px;
  @media (max-width: 1300px) {
    padding: 0 40px;
    box-sizing: border-box;
  }
  @media (max-width: 1000px) {
    margin: 0;
    margin-bottom: 100px;
  }

  @media (max-width: 650px) {
    margin-top: 0;
    margin-bottom: 80px;
    padding: 0 20px;
  }
`;
const Info = styled.div``;
const WrapperDescription = styled.div`
  text-align: center;
  max-width: 466px;
  margin: 0 auto;
  margin-bottom: 30px;
  @media (max-width: 1300px) {
  }
  @media (max-width: 650px) {
    margin: 0;
    max-width: 100%;
    margin-top: 24px;
  }
`;
const WrapperTitle = styled.div`
  text-align: center;
  margin-bottom: 32px;
  @media (max-width: 650px) {
    margin-bottom: 6px;
  }
`;
const Title = styled(Text)`
  width: fit-content;
  margin: 0 auto;
  line-height: 90px;
  @media (max-width: 650px) {
    line-height: 62px;
  }
`;
const Description = styled(Text)``;
const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 34px;
  justify-content: center;
  margin-top: 70px;
  @media (max-width: 1200px) {
    margin-top: 40px;
  }
  @media (max-width: 650px) {
    gap: 19px;
    margin-top: 32px;
  } ;
`;
const Item = styled.img`
  max-width: 220px;
  object-fit: cover;
  width: 100%;
  height: 100%;
  max-height: 121px;
  @media (max-width: 992px) {
    max-height: 100px;
    max-width: 160px;
  }
  @media (max-width: 650px) {
    max-height: 94px;
    max-width: 149px;
  }
`;
